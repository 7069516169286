export const Content = {
  blocks: [
    {
      actions: [
        {
          href: '/integrations/',
          label: 'Feedback Sources',
          type: 'link',
        },
      ],
      description: `Make sure every voice is heard regardless of where the conversation is happening.
      \n \n' Centralize feedback from all sources, public and private,
      into a repository that you can easily search to develop a deep understanding of the user experience.`,
      image: {
        url: '/images/v2/product/view-of-feedback.png',
      },
      textAlign: 'left',
      title: 'Get a 360° view of all feedback in one place',
    },
    {
      description: `Transform millions of unstructured pieces of data into actionable insights.
      Translate feedback from over 100 languages, apply sentiment analysis and group it into thousands
      of granular categories to quickly discover opportunities to improve your product and services.`,
      image: {
        url: '/images/v2/product/unlock-accurate-insights.png',
      },
      textAlign: 'right',
      title: 'Unlock accurate and real\u2011time user insights with  AI automation',
    },
    {
      actions: [
        {
          href: '/blog/visualize-user-feedback-with-unitq-charts-dashboards',
          label: 'Charts & Dashboards',
          type: 'link',
        },
      ],
      description: `Easily identify user trends and rally teams around what matters the most to your users. \n \n
      Build powerful charts and dashboards to keep track of emerging patterns, apply data filters to understand different user segments,
      and leverage AI to quickly summarize feedback around specific topics.`,
      image: {
        url: '/images/v2/product/create-tailored-views.png',
      },
      textAlign: 'left',
      title: 'Create tailored views and summaries for every need',
    },
    {
      actions: [
        {
          href: '/blog/impact-analysis-surfaces-critical-product-quality-issues-determines-impact-of-fixing-them',
          label: 'Impact Analysis',
          type: 'link',
        },
      ],
      description: `Turn user insights into business outcomes.
      Measure the quality of the user experience based on feedback with the unitQ score,
      benchmark your product against competitors,
      and prioritize development efforts to optimize key performance indicators (KPIs) such as NPS, CSAT,
      1\u2011star reviews, support tickets, brand  sentiment and more.`,
      image: {
        url: '/images/v2/product/make-data-driven-decisions.png',
      },
      textAlign: 'right',
      title: 'Make data-driven decisions faster to grow your business',
    },
    {
      actions: [
        {
          href: '/blog/stay-on-top-of-customer-feedback-with-ai-powered-alerts',
          label: 'Anomaly Alerts',
          type: 'link',
        },
      ],
      description: `Whether you are fixing a reported bug or releasing a highly requested feature,
      how fast you do it impacts the experience of your users.
      Integrate unitQ with your team's existing tools to accelerate the  speed of innovation - set up automatic Slack and
      Microsoft Teams alerts, create Jira tickets directly from unitQ, and more.`,
      image: {
        url: '/images/v2/product/quickly-take-action.png',
      },
      textAlign: 'left',
      title: 'Quickly take action to delight users',
    },
  ],
  hero: {
    src: '/images/v2/product/quality-trends-lg.png',
    subtitle: 'unitQ is the first GenAI built for Quality',
    title: 'Is the Quality of your product exceeding **user expectations?**',
  },
  seo: {
    appendHostUrl: true,
    description: 'Deliver high-quality experiences with AI user insights',
    src: '/images/v2/seo/product.jpg',
    title: 'Is the Quality of your product exceeding user expectations?',
  },
  solutions: {
    title: 'One solution for **every\u00A0team.**',
  },
};
